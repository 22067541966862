import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src362702145/src/www.shotwire.com/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Contact" description="Shotwire sales +1.424.229.2118 - sales@shotwire.com" mdxType="SEO" />
    <h1 {...{
      "id": "contact"
    }}>{`Contact`}</h1>
    <p>{`Shotwire sales `}</p>
    <p><strong parentName="p">{`Phone:`}</strong>{` +1.424.229.2118
`}<strong parentName="p">{`Email:`}</strong>{` `}<a parentName="p" {...{
        "href": "mailto:sales@shotwire.xyz",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`sales@shotwire.xyz`}</a></p>
    <h2 {...{
      "id": "video-products"
    }}>{`Video Products`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://video.shotwire.xyz/Cloud",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`SHOTWIRE CLOUD SERVICES`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://video.shotwire.xyz/Enterprise",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`SHOTWIRE ENTERPRISE SERVICES`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://video.shotwire.xyz/VideoSubscriptions",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`SHOTWIRE VIDEO CONTENT LICENSE SUBSCRIPTIONS`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "photo-products"
    }}>{`Photo Products`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      